import { FileFormatEnum } from 'types/generated/client';
import Pill from 'components/Pill';
import { PillType } from 'components/Pill/props';
import { formatCreationWithIntl } from 'components/Premium/utils';
import { sentimentMapper } from 'components/VideoAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { SkillSentimentCardProps } from './props';
import { getSkillLabel } from './utils';

const SkillSentimentCard: React.FC<SkillSentimentCardProps> = ({
  date,
  media,
  sentiment,
  detail,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        {sentiment && (
          <Pill text={getSkillLabel(sentiment)} type={sentimentMapper[sentiment] as PillType} />
        )}
        <span className="typography-product-card-title text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          {formatCreationWithIntl(date, false)}
        </span>
      </div>
      <p className="typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {detail}
      </p>

      {media &&
        (media.format === FileFormatEnum.Video || media.format === FileFormatEnum.Audio) && (
          <video
            width="100%"
            controls
            className={classNames(
              'mt-auto  rounded-xl object-contain',
              media.format === FileFormatEnum.Audio ? 'h-10' : 'h-80',
            )}
          >
            <source src={media.src} />
            Your browser does not support the video tag.
          </video>
        )}

      {media && media.format === FileFormatEnum.Image && (
        <img src={media.src} className="mt-auto h-80 rounded-xl object-cover" />
      )}
    </div>
  );
};

export default SkillSentimentCard;
